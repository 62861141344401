import { useState, useEffect } from 'react'
import { OrderPopup } from './orderPopup'

export const Header = (props) => {

  const [isOrderPopupOpen, setIsOrderPopupOpen] = useState(false)

  const [headerClass, setHeaderClass] = useState('day')
  
  useEffect(() => {
    const d = new Date();
    let hour = d.getHours();
    
    if(hour > 20 || hour < 5){
      setHeaderClass('night');
    }
  }, [])

  return (
    <>
      <header id='header' className={headerClass}>
        <div className='intro'>
          <div className='overlay'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-10 col-md-offset-1 intro-text'>
                  <h1>
                    {props.data ? props.data.title : 'Loading'}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                  <div
                    onClick={() => {setIsOrderPopupOpen(true)}}
                    className='btn btn-custom btn-lg page-scroll'
                  >
                    Заказать
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <OrderPopup open={isOrderPopupOpen} state={setIsOrderPopupOpen}/>
    </>
  )
}
