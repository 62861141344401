import { useState, useEffect, useMemo } from 'react'
import { Popup } from './popup'
import { OrderPopup } from './orderPopup'

export const Features = (props) => {
  const [selectedFeature, setFeature] = useState(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isOrderPopupOpen, setIsOrderPopupOpen] = useState(false)
  const openOrderPopup = () => {
      setIsOrderPopupOpen(true);
      setIsPopupOpen(false);
  }

  const featureContent = <>
    {selectedFeature && <>
      <div className="feature-popup-content">
         <img src={`../img/services/${selectedFeature.icon}.png`} />
         <h2>{selectedFeature.title}</h2>
         <p>{selectedFeature.text}</p>
       </div>
    </>}
  </>
  
  useEffect(() => {
    if(props.data){
      setFeature(props.data[0]);
    }
  }, [props.data])
  return (
    <>
      <div id='features' className='text-center'>
        <div className='container'>
          <div className='col-md-10 col-md-offset-1 section-title'>
            <h2>Что умеет&nbsp;дом</h2>
          </div>
          <div className='row desktop'>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className='col-xs-12 col-sm-6 col-md-4 service-place' onClick={() => {setFeature(d); setIsPopupOpen(true); setIsOrderPopupOpen(false)}}>
                    {' '}
                    <div className='service-icon'  style={{backgroundImage: `url(../img/services/${d.icon}.png)`}}>{' '}</div>
                    <div className='service-title'>{d.title}</div>
                  </div>
                ))
              : 'Loading...'}
          </div>
          <div className='row mobile'>
          {selectedFeature
            ?
            (
              <div className='service-place' onClick={() => {setIsPopupOpen(true)}}>
                <div className='service-icon'  style={{backgroundImage: `url(../img/services/${selectedFeature.icon}.png)`}}>{' '}</div>
                <div className='service-title'>{selectedFeature.title}</div>
              </div>
            ) : 'loading'}
            
          {selectedFeature
              ? props.data.map((d, i) => (
                  <div className={`${d.icon === selectedFeature.icon ? 'selected' : ''} radioItem`}  key={d.icon} onClick={() => {setFeature(d)}}>
                    {' '}
                  </div>
                ))
              : 'loading'}
          </div>
        </div>
      </div>
      <Popup open={isPopupOpen} state={setIsPopupOpen} content={featureContent} btnOnClick={openOrderPopup} />
      <OrderPopup open={isOrderPopupOpen} state={setIsOrderPopupOpen}/>
    </>
  ) 
}
