import { useState, useEffect } from 'react'


export const Popup = (props) => {
  const [open, setOpen] = useState(false)
  const [content, setContent] = useState('')
  useEffect(() => {
    setOpen(props.open);
  }, [props.open])
  useEffect(() => {
    setContent(props.content);
  }, [props.content])

  return (
    <>
        <div id="popup" className={`popup-${open ? 'open':'close'}`}>
            
            <div className="container">
                <div className="close-btn pull-right" onClick={() => props.state(false)}>{' '}</div>
                <div className="content">
                  { content }
                  {(props.btnShow === false) ?
                  <></> : <center><a onClick={() => props.btnOnClick()} className="btn btn-custom btn-lg">{props.btnText || 'Заказать'}</a></center>
                  }
                  
                </div>
            </div>
        </div>
    </>
  )
}
