export const Conception = (props) => {
  return (
    <div id='conception' class="text-center">
      <div className='container'>
          <div className='section-title'>
              <h2>Концепция</h2>
          </div>
          <div className="desc">
            <div>{' '}</div>
            <div>
              Для создания предоставления услуг<br />
              мы используем концепцию &laquo;‎лучший<br />
              интерфейс - это его отсутствие&raquo;.<br /><br />

              Чтобы максимально автоматизировать<br />
              процессы умного дома, составляются<br />
              паттерны поведения его хозяев,<br />
              что позволяет строить системы<br />
              реагирования высокого уровня так,<br />
              чтобы системы умного дома работали<br />
              как продолжение вашей руки<br />
              без каких-либо дополнительных<br />
              действий.
            </div>
          </div>
      </div>
    </div>
  )
}