import React from 'react';
import { useEffect, useState } from 'react'
import { Popup } from './popup'
import{ init, sendForm } from 'emailjs-com';

export const OrderPopup = (props) => {

    const usernameRef = React.useRef();
    const phoneRef = React.useRef();
    const commentRef = React.useRef();
    const [btnShow, setBtnShow] = useState(true)
    const [rand, setRand] = useState(Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5))


    const contentForm =  
    <div class="order-popup-content">
        <h2>Заявка</h2>
        <form name="orderForm" id={rand}>
            <div class="form-group row">
                <label for="inputName" class="col-sm-2 col-form-label">Имя*:</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control form-control-lg" id="inputName" name="input_name" ref={usernameRef}></input>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputPhone" class="col-sm-2 col-form-label">Телефон*:</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control form-control-lg" id="inputPhone" name="input_phone" ref={phoneRef}></input>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputComment" class="col-sm-2 col-form-label">Краткое описание:</label>
                <div class="col-sm-10">
                    <textarea class="form-control" id="inputComment" name="input_comment" rows="3" ref={commentRef}></textarea>
                </div>
            </div>
            <div class="form-group row">
                <label for="none" class="col-sm-2 col-form-label"></label>
                <div class="col-sm-10">
                    *Поля, обязательные для заполнения
                </div>
            </div>
        </form>
    </div>
    ;

    const contentSended =  
    <div class="order-popup-content">
        <h2>Заявка отправлена</h2>
    </div>

    const [content, setContent] = useState(contentForm)


    useEffect(() => {
        init('user_SyxLhqmmF3Vijy1xFyBFO')
    }, [])

    const handleSubmit = () => {

        console.log(usernameRef.current.value, phoneRef.current.value, commentRef.current.value);
        if(!usernameRef.current.value){
            usernameRef.current.focus()
            return;
        }

        if(!phoneRef.current.value){
            phoneRef.current.focus()
            return;
        }

        setBtnShow(false);
        
        console.log(rand)
        sendForm('service_flvk2hg', 'template_laltvel', `#${rand}`, 'user_SyxLhqmmF3Vijy1xFyBFO')
        .then(function() {
            console.log('Success');
        }, function(error) {
            console.log('Fail', error);
        })
        .finally(() => {
            setContent(contentSended);
            setTimeout(() => {
                setContent(contentForm);
                setBtnShow(true);
                props.state(false)
            }, 2000);
        })

    };

    
    return (
        <Popup open={props.open} state={props.state} content={content} btnOnClick={handleSubmit} btnText={'Отправить'} btnShow={btnShow} />
    )
}