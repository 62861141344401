import { useState, useEffect, useMemo } from 'react'
import { Popup } from './popup'
import { OrderPopup } from './orderPopup'

export const Projects = (props) => {
  const projects = [
    {
      image: '../img/projects/project-1.png',
      title: `2-х этажный дом в стиле hi-tech`,
      size: `324`,
      features: `Освещение/Климат/Безопасность/Мультирум/Домашний кинотеатр/Видеонаблюдение`,
      about: `
В ходе проекта по созданию умного дома был реализован следующий фнкционал:

Автоматическое включение и выключение света, световые сцены в зависимости от времени суток, нахождения жителей в определенных зонах, принудительный влючение заданных режимов освещения.

Вариативная светодиодная подстветка основных зон, ночные сцены следования без включения основного освещения.

Автоматическое следование заданным комфортным уровням влажности и температуры, с автоматизированным включение соответствующих обеспечивающих приборов.

Режим экономии и режим никого нет дома с отключением необязательных потребителей, автоматическое выключение оставленных приборов при уходе их дома.

Режим охраны с автоматическим включением видеокамер Мультирум с переключением зон между комнатами, возможность воспроизведения музыки из заранее подготовленных плейлистов, выбранных радиостанций, музыкальных сервисов.


Информационные и сопроводительные сообщения системы, предупреждения о состоянии сервисов, микроклимате и т. д.

Голосовое управление.

Создание адаптированных интерфейсов для удобного управления.
      `,
    imagesPopup: ['../img/projects/content/img1.png','../img/projects/content/img2.png','../img/projects/content/img3.png','../img/projects/content/img4.png','../img/projects/content/img5.png','../img/projects/content/img6.png'],
    },
    {
      image: '../img/projects/project-2.png',
      title: `2-х этажный дом в стиле hi-tech`,
      size: `324`,
      features: `Освещение/Климат/Безопасность/Мультирум/Домашний кинотеатр/Видеонаблюдение`,
      about: `
В ходе проекта по созданию умного дома был реализован следующий фнкционал:

Автоматическое включение и выключение света, световые сцены в зависимости от времени суток, нахождения жителей в определенных зонах, принудительный влючение заданных режимов освещения.

Вариативная светодиодная подстветка основных зон, ночные сцены следования без включения основного освещения.

Автоматическое следование заданным комфортным уровням влажности и температуры, с автоматизированным включение соответствующих обеспечивающих приборов.

Режим экономии и режим никого нет дома с отключением необязательных потребителей, автоматическое выключение оставленных приборов при уходе их дома.

Режим охраны с автоматическим включением видеокамер Мультирум с переключением зон между комнатами, возможность воспроизведения музыки из заранее подготовленных плейлистов, выбранных радиостанций, музыкальных сервисов.


Информационные и сопроводительные сообщения системы, предупреждения о состоянии сервисов, микроклимате и т. д.

Голосовое управление.

Создание адаптированных интерфейсов для удобного управления.
      `,
    imagesPopup: ['../img/projects/content/img1.png','../img/projects/content/img2.png','../img/projects/content/img3.png','../img/projects/content/img4.png','../img/projects/content/img5.png','../img/projects/content/img6.png'],
    },
    {
      image: '../img/projects/project-3.png',
      title: `2-х этажный дом в стиле hi-tech`,
      size: `324`,
      features: `Освещение/Климат/Безопасность/Мультирум/Домашний кинотеатр/Видеонаблюдение`,
      about: `
В ходе проекта по созданию умного дома был реализован следующий фнкционал:

Автоматическое включение и выключение света, световые сцены в зависимости от времени суток, нахождения жителей в определенных зонах, принудительный влючение заданных режимов освещения.

Вариативная светодиодная подстветка основных зон, ночные сцены следования без включения основного освещения.

Автоматическое следование заданным комфортным уровням влажности и температуры, с автоматизированным включение соответствующих обеспечивающих приборов.

Режим экономии и режим никого нет дома с отключением необязательных потребителей, автоматическое выключение оставленных приборов при уходе их дома.

Режим охраны с автоматическим включением видеокамер Мультирум с переключением зон между комнатами, возможность воспроизведения музыки из заранее подготовленных плейлистов, выбранных радиостанций, музыкальных сервисов.


Информационные и сопроводительные сообщения системы, предупреждения о состоянии сервисов, микроклимате и т. д.

Голосовое управление.

Создание адаптированных интерфейсов для удобного управления.
      `,
      imagesPopup: ['../img/projects/content/img1.png','../img/projects/content/img2.png','../img/projects/content/img3.png','../img/projects/content/img4.png','../img/projects/content/img5.png','../img/projects/content/img6.png'],
    },
    {
      image: '../img/projects/project-4.png',
      title: `2-х этажный дом в стиле hi-tech`,
      size: `54`,
      features: `Освещение/Климат/Безопасность/Мультирум/Домашний кинотеатр/Видеонаблюдение`,
      about: `
В ходе проекта по созданию умного дома был реализован следующий фнкционал:

Автоматическое включение и выключение света, световые сцены в зависимости от времени суток, нахождения жителей в определенных зонах, принудительный влючение заданных режимов освещения.

Вариативная светодиодная подстветка основных зон, ночные сцены следования без включения основного освещения.

Автоматическое следование заданным комфортным уровням влажности и температуры, с автоматизированным включение соответствующих обеспечивающих приборов.

Режим экономии и режим никого нет дома с отключением необязательных потребителей, автоматическое выключение оставленных приборов при уходе их дома.

Режим охраны с автоматическим включением видеокамер Мультирум с переключением зон между комнатами, возможность воспроизведения музыки из заранее подготовленных плейлистов, выбранных радиостанций, музыкальных сервисов.


Информационные и сопроводительные сообщения системы, предупреждения о состоянии сервисов, микроклимате и т. д.

Голосовое управление.

Создание адаптированных интерфейсов для удобного управления.
      `,
      imagesPopup: ['../img/projects/content/img1.png','../img/projects/content/img2.png','../img/projects/content/img3.png','../img/projects/content/img4.png','../img/projects/content/img5.png','../img/projects/content/img6.png'],
    },
  ];
  const [selectedProject, setProject] = useState(projects[0])
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isOrderPopupOpen, setIsOrderPopupOpen] = useState(false)
  const openOrderPopup = () => {
      setIsOrderPopupOpen(true);
      setIsPopupOpen(false);
  }

  const projectContent = <>
    {selectedProject && <>
      <div className="project-popup-content">
        <h2>{selectedProject.title}</h2>
        {selectedProject.imagesPopup
            ? selectedProject.imagesPopup.map((imageUrl,i) => (
              <img className='project-image' src={imageUrl} />
              ))
            : ''}
        <pre>{selectedProject.about}</pre>
       </div>
    </>}
  </>

  return (
    <>
      <div id='projects' className='text-center'>
        <div
          className='selectedProject'
          style={{backgroundImage: `url(${selectedProject.image})`}}
        >
          <div>{' '}</div>
          <div className='container radioBlock'>

            <div className='desc'>
                <div className="text"><b>{selectedProject.title}</b>,  {selectedProject.size} м<sup>2</sup><br/>{selectedProject.features}</div>
                <div
                    className='btn-small btn-project'
                    onClick={() => {setIsPopupOpen(true); setIsOrderPopupOpen(false)}}
                >
                    Подробнее >
                </div>
            </div>
            
            {projects
              ? projects.map((p,i) => (
                  <div className={`${p.image === selectedProject.image ? 'selected' : ''} radioItem`}  key={p.image} onClick={() => setProject(p)}>
                    {' '}
                  </div>
                ))
              : 'loading'}
          </div>
        </div>
        <div className='container'>
            <div className='section-title'>
                <h2>Проекты</h2>
            </div>
        </div>
      </div>
      <Popup open={isPopupOpen} state={setIsPopupOpen} content={projectContent} btnOnClick={openOrderPopup} />
      <OrderPopup open={isOrderPopupOpen} state={setIsOrderPopupOpen}/>
     </>
  )
}
