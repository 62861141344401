export const Company = (props) => {
  return (
    <div id='company' className='text-center'>
      <div className='container'>
          <div className='section-title'>
              <h2>О компании</h2>
          </div>
          <div className="desc">
            <div>
              <div className='p-title'>
                ITHome - <br />
                Территория инженеров
              </div><br />
              Работая более 10 лет<br />
              в ИТ, мы объединили свой<br />
              опят и знания для решения самых<br />
              разных управленческих задач, технических<br />
              и организационных задач. За нашими<br />
              плечами много успешных проектов<br />
              в области построения автоматизированных<br />
              информационных систем, начиная<br />
              от инфраструктурных сервисов заканчивая центрами<br />
              обработки данных и реализацией частных облаков.<br />
            </div>
            <div>{' '}</div>
          </div>
      </div>
    </div>
  )
}