import { useState } from 'react'
import { OrderPopup } from './orderPopup'

export const Contact = (props) => {

    const [isOrderPopupOpen, setIsOrderPopupOpen] = useState(false)

    return (
        <div>
            <div id='contact'>
                <div className='contact-container'>
                    <div id='map-place'>
                    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac152e8ee9e2819910fc6ee6a5fcf39de092e05fae6eeb86bc3a3ee786600caf7&amp;source=constructor" width="1280" height="720" frameborder="0"></iframe>
                            
                    </div>
                    <div className='container'>
                        <div className='section-title col-md-offset-4'>
                            <h2>Контакты</h2>
                        </div>
                        <div className='row'>
                            <div className='col-md-offset-4 col-md-8 text'>
                                Звоните нам:
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-offset-4 col-md-8 col-xs-12'>
                                <a className='contact-link' href='tel:+74993982676'>+7 (499) 398 26 76</a>
                                <div
                                   onClick={() => {setIsOrderPopupOpen(true)}}
                                    className='btn btn-call col-md-offset-2'
                                >
                                    Обратный звонок >
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-offset-4 col-md-8 text'>
                                Или пишите:
                            </div>
                            <div className='col-md-offset-4 col-md-6 col-xs-12'>
                                <a className='contact-link' href='mailto:info@ithome.studio'>info@ithome.studio</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='footer'>
                <div className='footer-container'>
                    <div className='container text-center'>
                        <div className='copyright'>©2021, IThome.studio. Все права защищены. 125124, г. Москва, 3‑я ул. Ямского Поля, дом 2, корпус 12
                        </div>
                        <a href="#" className='btn-up'>
                            {' '}
                        </a>
                    </div>
                </div>
            </div>
            <OrderPopup open={isOrderPopupOpen} state={setIsOrderPopupOpen}/>
        </div>
    )
}
